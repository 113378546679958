import React, { useState, useEffect } from "react";
import { useNavigate } from "../hooks";
import { LocalizedLink as Link } from "gatsby-theme-i18n";

import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import { useRegister } from "../mutations";
import produce from "immer";
import _ from "lodash";
import { ProtectComponentAfterSingin } from "../components/ProtectComponents";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../components/StrapiImage";
import { useAlert } from "react-alert";
import { useTrans } from "../hooks";

// ====================================================

const Register = ({ pageContext, location }) => {
  const { mainPhoto, seoData, layoutProps } = pageContext;

  const transMsg = useTrans();
  const alert = useAlert();
  const navigate = useNavigate();
  const { data, error, setData: setVariable } = useRegister();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const passwordMatch = () => {
    return formData.rePassword === formData.password;
  };

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      }),
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setVariable({
      variables: {
        username: formData.email,
        email: formData.email,
        password: formData.password,
        createdBy: process.env.GATSBY_STRAPI_RESTAURANT_ID,
      },
    });
  };

  useEffect(() => {
    if (_.isEmpty(data)) return;

    navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (_.isEmpty(error)) return;
    console.log(JSON.stringify(error));
    const message =
      error.graphQLErrors?.[0]?.extensions?.exception?.data?.message?.[0].messages?.[0]
        .message;
    if (message) alert.error(message);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <ProtectComponentAfterSingin location={location}>
      <Layout seo={seoData} showCart {...layoutProps}>
        <Container fluid className="bg-white">
          <Row>
            <Col md={4} lg={6} className="d-none d-md-flex bg-image">
              <StrapiImage
                src={mainPhoto?.url || ""}
                width="100%"
                size={STRAPI_IMAGE_SIZES.LARGE}
              />
            </Col>
            <Col md={8} lg={6}>
              <div className="login d-flex align-items-center py-5">
                <Container>
                  <Row>
                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                      <h3 className="login-heading mb-4">{transMsg("createAnAccount")} </h3>
                      <Form onSubmit={onSubmit}>
                        <div className="form-label-group">
                          <Form.Control
                            type="email"
                            id="inputEmail"
                            placeholder={transMsg("emailAddress")}
                            name="email"
                            required
                            onChange={onInputChange}
                          />
                          <Form.Label htmlFor="inputEmail">{transMsg("email")}</Form.Label>
                        </div>
                        <div className="form-label-group">
                          <Form.Control
                            type="password"
                            id="inputPassword"
                            placeholder={transMsg("password")}
                            name="password"
                            required
                            onChange={onInputChange}
                          />
                          <Form.Label htmlFor="inputPassword">
                            {transMsg("password")}
                          </Form.Label>
                        </div>
                        <div className="form-label-group mb-4">
                          <Form.Control
                            type="password"
                            id="rePassword"
                            name="rePassword"
                            placeholder={transMsg("confirmPassword")}
                            required
                            onChange={onInputChange}
                            isInvalid={!passwordMatch()}
                          />
                          <Form.Label htmlFor="rePassword">
                            {transMsg("confirmPassword")}
                          </Form.Label>
                        </div>
                        <Button
                          className="btn btn-lg btn-outline-primaryOFF btn-block btn-login text-uppercase font-weight-bold mb-2 round-btn theme-color-back"
                          type="submit"
                        >
                          {transMsg("signUp")}
                        </Button>
                        <div className="text-center pt-3">
                          {transMsg("alreadyHaveAnAccount")}{" "}
                          <Link className="font-weight-bold theme-color-text" to="/login">
                            {transMsg("signIn")}
                          </Link>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </ProtectComponentAfterSingin>
  );
};

export default Register;
